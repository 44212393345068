import { DOCUMENT, isPlatformBrowser, NgIf, NgFor, AsyncPipe } from '@angular/common';
import { Component, Inject, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterLink, RouterOutlet } from '@angular/router';
import { Observable, Subject, switchMap, timer } from 'rxjs';
import {
    distinctUntilChanged,
    filter,
    map,
    mapTo,
    mergeMap,
    startWith,
    take,
    throttleTime,
} from 'rxjs/operators';
import {
    GetActiveChannelDocument,
    GetAnnouncementsDocument,
    GetAnnouncementsQuery,
    GetMenuItemsDocument,
    GetMenuItemsQuery,
} from './common/gql/graphql';
import { DataService } from './core/providers/data/data.service';
import { NotificationService } from './core/providers/notification/notification.service';
import { StateService } from './core/providers/state.service';
import { JsonLd, JsonLdService } from './catalog/providers/json-ld.service';
import { ActiveService } from './core/providers/active-order/active.service';
import { ModalService } from './core/providers/modal/modal.service';
import { OrderMergedDialogComponent } from './shared/components/order-merged-dialog/order-merged-dialog.component';
import { HeaderScriptsService } from './core/providers/header-scripts/header-scripts.service';
import { headerScripts } from './header-scripts';
import { ReferralLinkService } from './core/providers/referral-link/referral-link.service';
import { LivechatComponent } from './core/components/livechat/livechat.component';
import { LayoutFooterComponent } from './core/components/layout/layout-footer.component';
import { PageMetadataDirective } from './core/components/page-metadata/page-metadata.directive';
import { CartDrawerComponent } from './core/components/cart-drawer/cart-drawer.component';
import { CollectionsMenuMobileComponent } from './core/components/collections-menu-mobile/collections-menu-mobile.component';
import { SideTrayComponent } from './shared/components/side-tray/side-tray.component';
import { CollectionsMenuComponent } from './core/components/collections-menu/collections-menu.component';
import { CartToggleComponent } from './core/components/cart-toggle/cart-toggle.component';
import { WishlistLinkComponent } from './core/components/wishlist-link/wishlist-link.component';
import { AccountLinkComponent } from './core/components/account-link/account-link.component';
import { ProductSearchBarComponent } from './core/components/product-search-bar/product-search-bar.component';
import { MobileMenuToggleComponent } from './core/components/mobile-menu-toggle/mobile-menu-toggle.component';
import { DisplayCurrencySelectComponent } from './core/components/display-currency-select/display-currency-select.component';
import { AnnouncementComponent } from './shared/components/announcement/announcement.component';
import { LayoutHeaderComponent } from './core/components/layout/layout-header.component';
import { LayoutComponent } from './core/components/layout/layout.component';
import { JsonLdComponent } from './shared/components/json-ld/json-ld.component';

@Component({
    selector: 'sf-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        JsonLdComponent,
        LayoutComponent,
        LayoutHeaderComponent,
        NgFor,
        AnnouncementComponent,
        RouterLink,
        DisplayCurrencySelectComponent,
        MobileMenuToggleComponent,
        ProductSearchBarComponent,
        AccountLinkComponent,
        WishlistLinkComponent,
        CartToggleComponent,
        CollectionsMenuComponent,
        SideTrayComponent,
        CollectionsMenuMobileComponent,
        CartDrawerComponent,
        RouterOutlet,
        PageMetadataDirective,
        LayoutFooterComponent,
        LivechatComponent,
        AsyncPipe,
    ],
})
export class AppComponent implements OnInit {
    cartDrawerVisible$: Observable<boolean>;
    mobileNavVisible$: Observable<boolean>;
    globalAnnouncements$: Observable<GetAnnouncementsQuery['announcementsForScope']>;
    isCheckoutFlow$: Observable<boolean>;
    menuItems$: Observable<GetMenuItemsQuery['menuItems']>;
    orgJsonLdData$: Observable<JsonLd>;
    searchJsonLdData$: Observable<JsonLd>;
    // Used to prevent unwanted opening of the collections menu after clicking
    // an overlaid product search bar result.
    isCollectionsMenuDisabled$: Observable<boolean>;
    private disableCollectionsMenu$ = new Subject<void>();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private dataService: DataService,
        private notificationService: NotificationService,
        private stateService: StateService,
        private jsonLdService: JsonLdService,
        private modalService: ModalService,
        private activeService: ActiveService,
        private headerScriptsService: HeaderScriptsService,
        private referralLinkService: ReferralLinkService,
        @Inject(PLATFORM_ID) private platformId: any,
        @Optional() @Inject(DOCUMENT) private document?: Document,
    ) {
        this.headerScriptsService.addScripts(headerScripts);
    }

    ngOnInit(): void {
        const isHomePage$ = this.router.events.pipe(
            filter((e) => e instanceof NavigationEnd),
            map((e: NavigationEnd) => {
                return /^\/$/.test(e.url);
            }),
        );
        this.orgJsonLdData$ = isHomePage$.pipe(
            map((isHomePage) => (isHomePage ? this.jsonLdService.getOrgData() : undefined)),
        );
        this.searchJsonLdData$ = isHomePage$.pipe(
            map((isHomePage) => (isHomePage ? this.jsonLdService.getSearchData() : undefined)),
        );
        this.cartDrawerVisible$ = this.stateService.select((state) => state.cartDrawerOpen);
        this.mobileNavVisible$ = this.stateService.select((state) => state.mobileNavMenuIsOpen);
        this.globalAnnouncements$ = this.dataService
            .query(
                GetAnnouncementsDocument,
                {
                    scope: 'global',
                },
                { ssr: true },
            )
            .pipe(map((res) => res.announcementsForScope));

        // Fetch the active channel up-front so that the formatPricePipe
        // can always fetch from cache
        this.dataService
            .query(GetActiveChannelDocument, {}, { fetchPolicy: 'network-only', ssr: true })
            .pipe(
                take(1),
                map((data) => data.activeChannel),
            )
            .subscribe();

        this.isCheckoutFlow$ = this.router.events.pipe(
            filter((event): event is NavigationEnd => event instanceof NavigationEnd),
            map((event) => event.url.startsWith('/checkout')),
            startWith(false),
        );
        this.activeService.activeOrder$
            .pipe(
                filter((activeOrder) => !!activeOrder?.mergedOrderLines.length),
                distinctUntilChanged(),
                throttleTime(5000),
                mergeMap((activeOrder) => {
                    return this.modalService.fromComponent(OrderMergedDialogComponent, {
                        locals: {
                            order: activeOrder,
                        },
                        closable: false,
                    });
                }),
            )
            .subscribe();
        this.menuItems$ = this.dataService
            .query(GetMenuItemsDocument, {}, { ssr: true })
            .pipe(map(({ menuItems }) => menuItems));

        if (isPlatformBrowser(this.platformId) && window.location.search) {
            const match = location.search.match(/referralCode=([^)]+)/);
            if (match && match[1]) {
                this.referralLinkService.validateReferralCode(match[1]);
            }
        }
        this.isCollectionsMenuDisabled$ = this.disableCollectionsMenu$.pipe(
            switchMap(() => timer(3000).pipe(mapTo(false), startWith(true))),
            startWith(false),
        );
        // We disabled Hotjar for now
        // this.setUpHotjarEvents();
    }

    openMobileNav() {
        this.stateService.setState('mobileNavMenuIsOpen', true);
    }

    closeMobileNav() {
        this.stateService.setState('mobileNavMenuIsOpen', false);
    }

    openCartDrawer() {
        this.stateService.setState('cartDrawerOpen', true);
    }

    closeCartDrawer() {
        this.stateService.setState('cartDrawerOpen', false);
    }

    temporarilyDisableCollectionsMenu() {
        this.disableCollectionsMenu$.next();
    }

    /**
     * Custom event so we can trigger the Hotjar recording after a certain amount of time.
     */
    private setUpHotjarEvents() {
        const startRecordingAfter = 1000 * 60 * 2;
        if (isPlatformBrowser(this.platformId)) {
            timer(startRecordingAfter)
                .pipe(take(1))
                .subscribe(() => {
                    (window as any).hj?.('trigger', 'minimum_visit_duration');
                });
        }
    }
}
