import { ChangeDetectionStrategy, Component, ElementRef, OnInit, Optional, ViewChild } from "@angular/core";
import { Observable, tap } from "rxjs";
import { map } from "rxjs/operators";

import { RouterLink } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgIf, AsyncPipe } from "@angular/common";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { CartFragment } from "../../../common/gql/graphql";
import { SideTrayComponent } from "../../../shared/components/side-tray/side-tray.component";
import { ActiveService } from "../../providers/active-order/active.service";
import { DataService } from "../../providers/data/data.service";
import { StateService } from "../../providers/state.service";
import { CartTotalsComponent } from "../../../shared/components/cart-totals/cart-totals.component";
import { FreeShippingNoticeComponent } from "../../../shared/components/free-shipping-notice/free-shipping-notice.component";
import { CartCouponsComponent } from "../../../shared/components/cart-coupons/cart-coupons.component";
import { CartContentsComponent } from "../../../shared/components/cart-contents/cart-contents.component";

@Component({
    selector: 'vsf-cart-drawer',
    templateUrl: './cart-drawer.component.html',
    styleUrls: ['./cart-drawer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        CartContentsComponent,
        FontAwesomeModule,
        CartCouponsComponent,
        FreeShippingNoticeComponent,
        CartTotalsComponent,
        RouterLink,
        AsyncPipe,
    ],
})
export class CartDrawerComponent implements OnInit {
    cart$: Observable<CartFragment | null | undefined>;
    canModifyCart$: Observable<boolean>;
    isEmpty$: Observable<boolean>;
    activeCouponCodes$: Observable<string[]>;
    availableRewardPoints$: Observable<number | null>;
    usedRewardPoints$: Observable<number>;
    @ViewChild('outer') outerDivRef: ElementRef<HTMLDivElement>
    @ViewChild('inner') innerDivRef: ElementRef<HTMLDivElement>
    shoppingCart = faShoppingCart;

    constructor(
        private dataService: DataService,
        private stateService: StateService,
        private activeService: ActiveService,
        @Optional() private sideTrayComponent?: SideTrayComponent,
    ) {}

    ngOnInit() {
        this.cart$ = this.activeService.activeOrder$.pipe(
            tap((order) => this.fixInnerHeightForSafari13(order))
        );
        this.canModifyCart$ = this.cart$.pipe(map((cart) => !!cart && cart.state === 'AddingItems'));
        this.isEmpty$ = this.cart$.pipe(map((cart) => !cart || cart.lines.length === 0));
        this.availableRewardPoints$ = this.activeService.activeCustomer$.pipe(
            map((activeCustomer) => {
                return activeCustomer?.customFields?.rewardPointsTotal ?? null;
            }),
        );
        this.usedRewardPoints$ = this.cart$.pipe(
            map((cart) => (cart && cart.customFields && cart.customFields.rewardPointsUsed) || 0),
        );
        this.activeCouponCodes$ = this.cart$.pipe(map((cart) => cart?.couponCodes ?? []));
    }

    checkoutClick() {
        if (this.sideTrayComponent) {
            this.sideTrayComponent.close.emit();
        }
    }

    private fixInnerHeightForSafari13(order: CartFragment) {
        const isSafari13 = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1 && navigator.userAgent.indexOf('Version/13') !== -1;
        if (isSafari13) {
            this.innerDivRef.nativeElement.style.minHeight = order.lines.length * 150 + 800 + 'px';
        }
    }
}
